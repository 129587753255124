import recordFileDownloadMutation from "utils/graphql/fileDownload/recordFileDownload/recordFileDownload"

/**
 * mutates the total dataDownload count of a given fileName
 * @param  {Object} client  ApolloClient
 * @param  {String} fileName your file URL
 * @return {Promise}        a Promise that resolves to the number of times the file was downloaded
 */

export default function recordFileDownload(client, fileName="") {
  return client.mutate({
    mutation: recordFileDownloadMutation(fileName)
  }).then((response)=>{
    if(response?.data?.addCurrentUserDownload?.results?.[0]?.fileName !== fileName) { throw Error("The response format was incorrect"); }
    return response.data.addCurrentUserDownload.results[0].fileName
  })
}
