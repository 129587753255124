import React from "react"
import { Router } from "@reach/router"
import Datapage from "components/dataDownload/datapage/Datapage.js"

const DatapageRouter = () => {
  return (
    <Router basepath="/data-download/datasets">
        <Datapage path="/:id" />
      </Router>
  )
}
export default DatapageRouter