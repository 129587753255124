import getTotalDownloads from "utils/graphql/fileDownload/getTotalDownloads/getTotalDownloads"

/**
 * returns a promise for the number of file downloads for your file URL
 * @param  {Object} client  ApolloClient
 * @param  {String} fileName your file URL
 * @return {Promise}        a Promise that resolves to the number of times the file was downloaded
 */
export default function getNumberOfDownloads(client, fileName="") {
  return client.query({
    query: getTotalDownloads(fileName),
  }).then((response) => {
    if (!response?.data?.getFileNDownloads?.[0]?.nDownloads) { throw Error("The response format was incorrect"); }
    return response.data.getFileNDownloads[0].nDownloads;
  })
}
