import gql from 'graphql-tag';

/**
 * given a file name string, record the file download
 * @param  {String} fileName  file name string being downloaded
 * @return {Graphql Mutation}
 */
export default function recordFileDownload(fileName) {
  return gql`
  mutation MyMutation {
    __typename
    addCurrentUserDownload(input: {_fileName: "${fileName}"}) {
      results {
        dateDownload
        fileName
      }
    }
  }
  `
}
